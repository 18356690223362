<template>
   <div>
      <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
         <template v-slot:item="{ item }">
            <v-breadcrumbs-item
               href="#"
               :disabled="item.disabled"
               >
               {{ item.text }}
            </v-breadcrumbs-item>
         </template>
      </v-breadcrumbs>
      <v-card class="mx-auto" outlined tile color="#F8F8FF">
         <v-card flat>
            <v-card-title>Profile Details</v-card-title>
            <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
               <v-container class="fill-height"
                  fluid>
                  <v-row>
                      <!--
                     <v-col cols="12" md="12">
                        <v-text-field
                           dense
                           outlined
                           v-model="form.ID"
                           label="ID"
                           required
                           disabled
                           hide-details
                           ></v-text-field>
                     </v-col>
                     -->
                     <v-col cols="12" md="12">
                        <v-text-field
                           dense
                           outlined
                           v-model="form.userID"
                           label="User ID"
                           required
                           disabled
                           hide-details
                           ></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-text-field
                           dense
                           outlined
                           v-model="form.name.name"
                           :rules="form.name.rule"
                           label="Name"
                           required
                           :disabled="readonly"
                           hide-details="auto"
                           ></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-text-field
                           dense
                           outlined
                           v-model="items.loginEmailID"
                           label="Email ID"
                           required
                           disabled
                           hide-details
                           ></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-text-field
                           dense
                           outlined
                           v-model="form.email"
                           label="Email"
                           required
                           disabled
                           hide-details
                           ></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-text-field
                           dense
                           outlined
                           v-model="form.profileid"
                           label="Profile ID"
                           required
                           disabled
                           hide-details
                           ></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-autocomplete
                           dense
                           outlined
                           placeholder="Select Type"
                           label="Type"
                           v-model="type.selected"
                           :items="type.items"
                           item-text="name"
                           item-value="ID"
                           disabled
                           :rules="type.rule"
                           :loading="type.loader"
                           hide-details="auto"
                           >
                        </v-autocomplete>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-autocomplete
                           dense
                           outlined
                           placeholder="Select Role"
                           label="Role"
                           v-model="form.role.selected"
                           :items="form.role.items"
                           item-text="name"
                           item-value="ID"
                           :disabled="readonly"
                           :rules="form.role.rule"
                           :loading="form.role.loader"
                           hide-details="auto"
                           >
                        </v-autocomplete>
                     </v-col>
                     <v-col cols="12" md="12">
                        <!-- <span class="text-body-2"><a style="text-decoration: underline" id="custom-hover" class="collapsible" @click="viewPerms()">Role Description</a></span> -->
                        <button type="button" class="collapsible" style="padding: unset"><a style="text-decoration: underline" id="custom-hover">Role Description</a></button>
                        <div class="content">
                            <v-row v-if="!loading">
                                <v-col cols="6" v-for="(item, index, i) of perm_list_grouped" :key="i" v-if="perm_list_grouped[index][0].selected.length > 0">
                                    <span class="text-body-2">{{perm_list_grouped[index][0].parent_name}}</span>
                                    <v-divider v-if="perm_list_grouped[index][0].selected.length > 0"></v-divider>
                                    <span v-if="perm_list_grouped[index][0].selected.length > 0">
                                    <ul v-for="(it, idx) of perm_list_grouped[index]" :key="idx" v-if="it.selected.length > 0">
                                        <li><span class="text-caption">{{it.name}} - <span>{{it.action.map(content => content.label).join(', ')}}</span></span></li>
                                    </ul>
                                    </span>
                                </v-col>
                            </v-row>
                        </div>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-text-field
                           dense
                           outlined
                           v-model="form.createddate"
                           label="Created Date"
                           required
                           disabled
                           hide-details
                           ></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-text-field
                           dense
                           outlined
                           v-model="form.updateddate"
                           label="Updated Date"
                           required
                           disabled
                           hide-details
                           ></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-text-field
                           dense
                           outlined
                           v-model="form.lastlogindate"
                           label="Last Login Date"
                           required
                           disabled
                           hide-details
                           ></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <span class="text-body-2">Status</span>
                        <v-switch
                           color="success"
                           class="col-1 pl-0"
                           v-model="form.status"
                           :label="`${form.status ? 'Enabled' : 'Disabled'}`"
                           :disabled="readonly"
                           hide-details
                           ></v-switch>
                     </v-col>
                     <v-col cols="12" md="12">
                    <span class="text-body-2">Choose Operator Access to give access to</span>
                    <v-checkbox dense hide-details @change="selectAll_operator" class="col-5 pl-0" v-model="select_all_operator">
                        <template v-slot:label class="checkbox-role"><span style="width: 100%">All Operator</span></template>
                    </v-checkbox>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Or select specifically from below</span>
                </v-col>
                <v-col cols="12" offset-md="6" md="6">
                    <v-text-field placeholder="Search Operator" outlined class="search-textfield" hide-details dense v-model="search_operator"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        :headers="form.operator.header"
                        v-model="form.operator.selected"
                        :items="form.operator.items"
                        show-select
                        item-key="OperatorID"
                        hide-default-footer
                        :search="search_operator"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        dense
                        @toggle-select-all="removeSelectAll_operator"
                        @input="execFilter('operator')"
                        disable-pagination
                        v-scroll:#virtual-scroll-table-op="onScroll_operator"
                        id="virtual-scroll-table-op"
                    >
                    <template
                        v-if="start_operator > 0"
                        v-slot:body.prepend
                    >
                        <tr>
                        <td
                            :colspan="form.operator.header.length"
                            :style="'padding-top:'+startHeight_operator+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    <template
                        v-if="start_operator + perPage_operator < form.operator.items.length"
                        v-slot:body.append
                    >
                        <tr>
                        <td
                            :colspan="form.operator.items.length"
                            :style="'padding-top:'+endHeight_operator+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    </v-data-table>
                    <v-input
                        error
                        readonly
                        v-model="form.operator.selected"
                        :rules="form.operator.rule"
                        height="0"
                        class="hide-input-text mt-2"
                    >
                    </v-input>
                </v-col>
                     <v-col cols="12" md="12">
                        <span class="text-body-2">Choose Car Park Access to give access to</span>
                        <v-checkbox dense hide-details @change="selectAll" class="col-2 pl-0" v-model="select_all" :disabled="readonly">
                           <template v-slot:label class="checkbox-role"><span style="width: 100%">All Car Park</span></template>
                        </v-checkbox>
                     </v-col>
                     <v-col cols="12" md="12">
                        <span class="text-body-2">Or select specifically from below</span>
                     </v-col>
                     <v-col cols="12" offset-md="6" md="6">
                        <v-text-field placeholder="Search Car Park" outlined class="search-textfield" hide-details dense v-model="search"></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-data-table
                           :headers="form.carpark.header"
                           v-model="form.carpark.selected"
                           :items="form.carpark.items"
                           show-select
                           :single-select="readonly"
                           item-key="CarParkID"
                           hide-default-footer
                           :search="search"
                           loading-text= "Loading Data... Please wait"
                           :items-per-page="100"
                           dense
                           @toggle-select-all="removeSelectAll"
                           disable-pagination
                           v-scroll:#virtual-scroll-table-cp="onScroll"
                           id="virtual-scroll-table-cp"
                           >
                           <template
                              v-if="start > 0"
                              v-slot:body.prepend
                              >
                              <tr>
                                 <td
                                    :colspan="form.carpark.header.length"
                                    :style="'padding-top:'+startHeight+'px'"
                                    >
                                 </td>
                              </tr>
                           </template>
                           <template
                              v-if="start + perPage < form.carpark.items.length"
                              v-slot:body.append
                              >
                              <tr>
                                 <td
                                    :colspan="form.carpark.items.length"
                                    :style="'padding-top:'+endHeight+'px'"
                                    >
                                 </td>
                              </tr>
                           </template>
                           <template v-slot:item.data-table-select="{ item, isSelected, select }">
                              <v-simple-checkbox
                                 :value="isSelected"
                                 :readonly="readonly"
                                 :disabled="readonly"
                                 @input="select($event)"
                                 ></v-simple-checkbox>
                           </template>
                        </v-data-table>
                        <v-input
                           error
                           readonly
                           v-model="form.carpark.selected"
                           :rules="form.carpark.rule"
                           height="0"
                           class="hide-input-text mt-2"
                           >
                        </v-input>
                     </v-col>
                     <v-card-actions>
                        <v-btn v-if="validateAccess('businesscpo_usersupdate')" class="mt-4" tile small color="primary" type="submit" :loading="btn_loading">
                           <v-icon left x-small>fa fa-save</v-icon>
                           Save
                        </v-btn>
                        <v-btn class="mt-4" tile small color="error" type="button" @click="goBack">
                           <v-icon left x-small>fa fa-ban</v-icon>
                           Cancel
                        </v-btn>
                        <v-btn v-if="validateAccess('businesscpo_usersdelete')" @click="viewDelete(items, 'delete')" class="mt-4" tile small color="error" type="button">
                           <v-icon left x-small>fa fa-trash</v-icon>
                           Remove Profile
                        </v-btn>
                        <v-btn v-if="validateAccess('businesscpo_usersreset')" @click="viewMore('reset')" class="mt-4" tile small outlined color="error" type="button">
                           <v-icon left x-small>fa fa-redo-alt</v-icon>
                           Reset Password
                        </v-btn>
                     </v-card-actions>
                  </v-row>
               </v-container>
            </v-form>
         </v-card>
         <v-overlay
            :absolute="absolute"
            :value="overlay"
            >
            <v-progress-circular
               indeterminate
               color="primary"
               ></v-progress-circular>
         </v-overlay>
      </v-card>
      <v-snackbar
         v-model="swal.notification"
         top
         :color="swal.scolor"
         >
         {{ swal.message }}
      </v-snackbar>
      <alertbox :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      <alertdelete :dialog="ma.modal_delete" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :item="ma.item" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></alertdelete>
      <alertbox :dialog="ma.modal_reset" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
   </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import edit from '.././modals/EditRate';
import alertbox from '../modals/Alert';
import alertdelete from '../modals/AlertDeleteProfile';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";
import * as AppStorage from '../../assets/js/storage';
import {rule} from '../../assets/js/checkPerm';
import {perm_list} from '@/assets/js/permcustomer.js';
import _ from 'lodash';

export default {
    name: 'User Profile Details',
    title: 'User Profile Details',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Business',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park Operator',
                disabled: true,
                href: '#',
                },

                {
                text: 'Users',
                disabled: true,
                href: '#',
                },

                {
                text: 'Operator Profile Details',
                disabled: true,
                href: '#',
                },
            ],
            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            type: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            items: {},
            search: '',
            tableloading: false,
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Admin ID', value: 'ProfileAdminID', width: '5%' },
                { text: 'Profile ID', value: 'ProfileID', width: '5%' },
                { text: 'System', value: 'SystemName', width: '5%' },
                { text: 'Operator', value: 'OperatorName', width: '10%' },
                { text: 'Car Park', value: 'CarParkName', width: '10%' },
                { text: 'Created Date', value: 'createDate', width: '5%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Updated Date', value: 'updateDate', width: '5%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Action', value: 'action', width: '10%' },
            ],
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                alertmodal: false,

                modal_update: false,
                modal_delete: false,
                modal_reset: false
            },
            form: {
                profileid: '',
                ID: '',
                userID: '',
                name: {name: '', rule: []},
                email: '',
                status: false,
                createddate: '',
                updateddate: '',
                lastlogindate: '',
                operator: {
                    header: [
                        { text: 'ID', value: 'OperatorID', width: '5%' },
                        { text: 'Name', value: 'name', width: '90%' },
                    ],
                    items: [],
                    itemsFull: [],
                    selected: [],
                    loader: false,
                    disable: false,
                    rule: [],
                    forapi: ''
                },
                carpark: {
                    header: [
                        { text: 'ID', value: 'CarParkID', width: '5%' },
                        { text: 'Name', value: 'name', width: '90%' },
                    ],
                    items: [],
                    itemsFull: [],
                    selected: [],
                    loader: false,
                    disable: false,
                    rule: [],
                    forapi: ''
                },
                role: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
            },
            componentloader: false,
            role_selected: [],
            perm_list: [],
            perm_list_grouped: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false,

            start: 0,
            timeout: null,
            rowHeight: 24,
            perPage: 25,
            deletetype: '',
            readonly: false,

            search_operator: '',
            select_all_operator: false,

            start_operator: 0,
            timeout_operator: null,
            rowHeight_operator: 10,
            perPage_operator: 10,
        }
    },

    components: {edit, alertbox, 'text-editor': TextEditor, alertdelete},

    async created(){
        this.$vuetify.goTo(0);
        if (!this.validateAccess('businesscpo_usersupdate')){
            this.readonly = true;
        }
        await this.getAllSystem();
        await this.getOperatorFull();
        await this.getProfileType();
        await this.getProfileRole();
        await this.getCarParkFull();
        await this.initVal();

        this.$watch(() => this.form.operator.selected, function(value) {
            if (value.length > 0){
                this.select_all_operator = false;
            }
        });

        this.$watch(() => this.form.carpark.selected, function(value) {
            if (value.length > 0){
                this.select_all = false;
            }
        });

        await this.listPerms();
        let grouppedArray=_.groupBy(this.perm_list,'parent_id');
        console.log('grouppedArray', grouppedArray);
        this.perm_list_grouped = grouppedArray;

        let coll = document.getElementsByClassName("collapsible");
        let i;

        for (i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function() {
                this.classList.toggle("active");
                let content = this.nextElementSibling;
                if (content.style.maxHeight){
                content.style.maxHeight = null;
                } else {
                content.style.maxHeight = content.scrollHeight + "px";
                }
            });
        }
    },

    async beforeRouteLeave (to, from , next) {
        AppStorage.remove("opusrdetails");
        next();
    },

    computed: {
        itemsLimited() {
        return this.form.carpark.items.slice(this.start, this.perPage+this.start);
        },
        startHeight() {
        return this.start * this.rowHeight - 32;
        },
        endHeight() {
        return this.rowHeight * (this.form.carpark.items.length - this.start);
        },

        itemsLimited_operator() {
        return this.form.operator.items.slice(this.start_operator, this.perPage_operator+this.start_operator);
        },
        startHeight_operator() {
        return this.start_operator * this.rowHeight_operator - 32;
        },
        endHeight_operator() {
        return this.rowHeight_operator * (this.form.operator.items.length - this.start_operator);
        },
    },

    methods: {
        async getAllSystem(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;

                        this.system.items.push(dt[i]);
                        this.system.itemsFull.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getOperatorFull(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllOperator", {search: ''});
                let dt = response.data.record;
                
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;

                        this.form.operator.items.push(dt[i]);
                        this.form.operator.itemsFull.push(dt[i]);
                        }
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllCarpark2", {});
                let dt = response.data.record;
                //this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                //this.form[2].itemsFull.push({name: "Select Car Park", CarParkID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                        dt[i].name =  `${dt[i].CarParkID} - ${dt[i].CarParkName}`;

                        this.form.carpark.items.push(dt[i]);
                        this.form.carpark.itemsFull.push(dt[i]);
                        }
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getProfileType(){
            let list = [];
            this.type.items = [];
            this.type.selected = "";

            try{
                this.type.loader = true;
                this.type.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listProfileTypeCustomer", {});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.type.items.push({name: "Select Type", ID: ""});
                    this.type.itemsFull.push({name: "Select Type", ID: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].ID} - ${dt[i].TypeName}`;
                        this.type.items.push({name: dt[i].name, ID: dt[i].ID});
                        this.type.itemsFull.push(dt[i]);
                    }
                }

                this.type.loader = false;
                this.type.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getProfileRole(){
            let list = [];
            this.form.role.items = [];
            this.form.role.selected = "";

            try{
                this.form.role.loader = true;
                this.form.role.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listProfileRoleCustomer", {});

                let response2 = await this.$store.dispatch("viewAccessCustomer", {});
                let getcheckaccess = response2.data.record.find(item => item.Path == "/api/checktoken");
                let getcheckaccess2 = getcheckaccess.TypeRole.slice(1, -1).split(', ');
                let checkaccesstoobj = [];
                for (let i=0; i<getcheckaccess2.length; i++){
                    let type = Number(getcheckaccess2[i].split('#')[0]);
                    let role = Number(getcheckaccess2[i].split('#')[1]);
                    checkaccesstoobj.push({type: type, role: role});
                }
                let type_operator = checkaccesstoobj.filter(itm => itm.type == 2);

                let dt = response.data.record.filter(a => type_operator.some(b => this.compareRoles(a, b)));
                if (response.data.code == 'AP000'){
                    this.form.role.items.push({name: "Select Role", ID: ""});
                    this.form.role.itemsFull.push({name: "Select Role", ID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].RoleName != 'Driver' && dt[i].RoleName != 'Corporate'){
                            dt[i].name = `${dt[i].ID} - ${dt[i].RoleName}`;
                            this.form.role.items.push({name: dt[i].name, ID: dt[i].ID});
                            this.form.role.itemsFull.push(dt[i]);
                        }
                    }
                }

                this.form.role.loader = false;
                this.form.role.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        compareRoles(a, b){
            return a.ID == b.role;
        },

        async updateProfile(){
            try{
                this.tableloading = true;
                let response;
                let response2;
                if (this.items.ProfileID == null || this.items.ProfileID == "N/A"){
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `Not handled yet`;
                    /*
                    let req = {userid: this.items.userID, type: this.type.selected, role: this.form.role.selected};
                    response = await this.$store.dispatch("createProfile", req)

                    if (response.data.code === 'AP000'){
                        this.$router.push({name: 'Users', query: {action: 'createProfile'}});
                    }

                    else{
                        this.tableloading = false;
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `Error`;
                    }
                    */
                }

                else{
                    let req = {profileid: this.items.ProfileID, loginemailid: this.items.loginEmailID, operatorusername: this.form.name.name, type: this.items.Type, role: this.form.role.selected, operator: this.form.operator.forapi, carpark: this.form.carpark.forapi};
                    response = await this.$store.dispatch("updateProfileCustomer", req)
                    let req2 = {operatoraccountid: this.items.OperatorAccountID, operatorusername: this.form.name.name};

                    let status = this.form.status == false ? 1 : 0;
                    await this.$store.dispatch("updateMobileSubscriberStatus", {loginemailid: this.items.loginEmailID, lockaccountstatus: status})
                    await this.$store.dispatch("updateProfileCustomerName", req2)

                    //let req2 = {adminid: this.items.adminID, name: this.form.name.name, activestatus: this.form.status};
                    //response2 = await this.$store.dispatch("userUpdateAccount", req2)

                    if (response.data.code === 'AP000' /* && response2.data.code === 'AP000' */){
                        this.$router.push({name: 'Users', query: {action: 'createProfile'}});
                    }

                    else{
                        this.tableloading = false;
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `Error`;
                    }
                }
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async deleteProfile(){
            try{
                this.tableloading = true;
                let req = {profileid: this.items.ProfileID};
                let response = await this.$store.dispatch("deleteProfileCustomer", req)
                
                if (response.data.code === 'AP000'){
                    this.$router.push({name: 'Users', query: {action: 'profileremoval'}});
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async userReset(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("userResetCustomer", {email: this.form.email})
                if (response.data.code === 'AP000'){
                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Password has been reset!`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async listPerms(){
            this.loading = true;
            this.perm_list = await perm_list.list();
            let self = this;
            try{
                let response = await this.$store.dispatch('viewAccessCustomer', {});
                this.access_record = response.data.record;
                if (response.data.code === 'AP000'){
                    let dt = response.data.record;

                    for(let [index, item] of this.perm_list.entries()){
                        for(let [id, it] of item.action.entries()){

                            const urls = Array.from(new Set(dt.map(a => a.Path)));
                            const result = it.url.every(val => urls.includes(val));
                            
                            let cnt = 0;
                            let pass = false;
                            for (let i=0; i<it.url.length; i++){
                                let TypeRole = dt.map(item => item.TypeRole.slice(1,-1).split(','));
                                let Type = TypeRole.map(item => item.map(item2 => item2.split('#')[0]));
                                let Role = TypeRole.map(item => item.map(item2 => item2.split('#')[1]));
                                let newdt = dt.map(function(entry) {
                                    entry.type = entry.TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[0]);
                                    entry.role = entry.TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[1]);
                                    entry.newTypeRole = {type: [...entry.TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[0])],
                                                         role: [...entry.TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[1])]};
                                    return entry;
                                });

                               let getPath = newdt.filter(tr => 
                                                    tr.Path == it.url[i]
                                                   );
                                let getTR = getPath.some(function (tr, indexMain) {
                                                let idx = 0; let truth;
                                                for (let ij=0; ij<tr.newTypeRole.type.length; ij++){
                                                    if (Number(tr.newTypeRole.type[ij]) == Number(self.type.selected) && Number(tr.newTypeRole.role[ij]) == Number(self.form.role.selected))
                                                        truth = true;
                                                }

                                                return truth;
                                            });

                                if (getTR){
                                    cnt++;

                                    if (cnt == it.url.length){
                                        pass = true;
                                        //console.log(pass);
                                    }
                                }
                            }
                            if (pass){
                                this.perm_list[index].selected.push(it.id);
                            }
                        }
                    }
                }

                else
                {
                    this.swal.scolor = 'red';
                    this.swal.notification = true;
                    this.swal.message = ERR.HANDLE(response.data.code);
                }

                this.loading = false;
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Server error. Unable to fetch results";
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        goBack(){
            this.$router.push({name: 'Users'});
        },

        async initVal(){
            this.form.carpark.forapi = '';
            if (this.$route.query.row !== undefined) AppStorage.set("opusrdetails", this.$route.query.row);
            this.$router.replace({'query': null});
            this.items = this.$route.query.row || AppStorage.get('opusrdetails');
            this.items = JSON.parse(this.items);
            this.form.profileid = this.items.ProfileID;
            this.form.ID = this.items.ID;
            this.form.userID = this.items.userID;
            this.form.name.name = this.items.OperatorUserName;
            //this.form.operator.selected = this.items.OperatorID;
            this.form.email = this.items.email;
            this.form.status = this.items.lockStatus == 0 ? 1 : 0;
            this.form.role.selected = this.items.Role == undefined ? "" : this.items.Role;
            this.type.selected = this.items.Type == undefined ? "" : this.items.Type;

            this.form.createddate = this.items.OperatorDetailCreatedDateTime;
            this.form.updateddate = this.items.OperatorDetailUpdatedDateTime;
            this.form.lastlogindate = this.items.lastLoginDate;

            //carpark
            let carparkid = this.items.Carpark.slice(1, -1).split(',');
            if (carparkid.includes("4ALLCP")){
                this.select_all = true;
            }

            else{
                let cpid = this.form.carpark.itemsFull.filter(itm => carparkid.map(Number).includes(itm.CarParkID));
                this.form.carpark.selected = cpid;
            }

            //operator
            let operatorid = this.items.Operator.slice(1, -1).split(',');
            if (operatorid.includes("4ALLOP")){
                this.select_all_operator = true;
            }

            else{
                let opid = this.form.operator.itemsFull.filter(itm => operatorid.map(Number).includes(itm.OperatorID));
                this.form.operator.selected = opid;
            }
        },

        async execFilter(type){
            if (type == 'operator'){
                console.log('eventwork');
                let self = this;
                if (self.form.operator.selected.length > 0){
                    //let carpark = this.form[2].itemsFull.filter(item => {
                      //  return item.OperatorID == self.form[1].selected;
                    //});

                    let selectedop = this.form.operator.selected.map(item => item.OperatorID);
                    console.log('selectedop', selectedop);
                    let carpark = this.form.carpark.itemsFull.filter(itm => selectedop.includes(itm.OperatorID));

                    this.form.carpark.items = [];
                    //this.form[2].selected = [];

                    //this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                    for(var i=0; i<carpark.length; i++){
                        //carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        //this.carpark.items.push(carpark[i]);

                        carpark[i].name =  `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;

                        this.form.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }
                }

                if (self.form.operator.selected.length == 0){
                    this.form.carpark.items = [];
                    //this.form[2].selected = [];

                    if (this.select_all_operator){
                        let selectedop = this.form.operator.itemsFull.map(item => item.OperatorID);
                        let carpark = this.form.carpark.itemsFull.filter(itm => selectedop.includes(itm.OperatorID));

                        for(var i=0; i<carpark.length; i++){
                            carpark[i].name =  `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                            this.form.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                        }
                    }
                }
            }

            let op = this.form.operator.items.map(itm => itm.OperatorID);
            let removeselectedoperatornotshown = this.form.operator.selected.filter(itm => op.includes(itm.OperatorID));
            this.form.operator.selected = removeselectedoperatornotshown;

            let cp = this.form.carpark.items.map(itm => itm.CarParkID);
            let removeselectedcarparknotshown = this.form.carpark.selected.filter(itm => cp.includes(itm.CarParkID));
            this.form.carpark.selected = removeselectedcarparknotshown;
        },

        onRuleChange(after, before) {
            this.form.name.rule = [];
            this.form.role.rule = [];
            this.form.carpark.rule = [];
        },

        async beforeCreateForm(flag){
            await this.onRuleChange();

            this.form.name.rule = [
                v => !!v || "Name is required"
            ];

            this.form.role.rule = [
                v => !!v || 'Role is required'
            ];

            this.form.operator.rule = [
                v => !!v || 'Operator is required',
            ];

            if (!this.select_all_operator){
                this.form.operator.rule = [
                    v => !v.length == 0 || 'Field is required',
                ];
            }

            if (!this.select_all){
                this.form.carpark.rule = [
                    v => !v.length == 0 || 'Field is required',
                ];
            }

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },
        
        viewMore(flag){
            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'reset'){
                this.ma.modal_reset = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewDelete(row, flag){
            if (flag == 'delete'){
                this.ma.modal_delete = true;
                this.ma.modalInfo = row;
            }

            this.ma.modal_action_type = 'delete';
            this.deletetype = 'deleteprofile'
        },

        viewPerms(){
            this.$router.push({name: 'Role Description', params: {role_id: this.form.role.selected, type_id: this.type.selected}});
        },

        onClosed(value){
            if (value == false){
                this.ma.modal = value;
                this.ma.alertmodal = value;
                this.ma.modal_delete = value;
                this.ma.modal_reset = value;
                this.ma.modal_update = value;
            }
        },

        onConfirm(value){
            if (value == true){
                if (this.ma.modal_action_type == 'update')
                {
                    this.ma.alertmodal = false;
                    if (this.select_all){
                        this.form.carpark.forapi = '[4ALLCP]';
                    }

                    else{
                        let carpark = this.form.carpark.selected.map(item => item.CarParkID);
                        this.form.carpark.forapi = `[${carpark.join()}]`;
                    }

                    if (this.select_all_operator){
                        this.form.operator.forapi = '[4ALLOP]';
                    }

                    else{
                        let operator = this.form.operator.selected.map(item => item.OperatorID);
                        this.form.operator.forapi = `[${operator.join()}]`;
                    }
                    
                    this.updateProfile();
                }
                //this.addValuePass(formatteddays);

                if (this.ma.modal_action_type == 'delete' && this.deletetype == 'deleteprofile')
                {
                    this.ma.modal_delete = false;
                    this.deleteProfile();
                }

                if (this.ma.modal_action_type == 'reset')
                {
                    this.ma.modal_reset = false;
                    this.userReset();
                }
            }
        },

        onScroll(e) {
            // debounce if scrolling fast
            this.timeout && clearTimeout(this.timeout);
        
            this.timeout = setTimeout(() => {
                const { scrollTop } = e.target;
                const rows = Math.ceil(scrollTop / this.rowHeight);

                this.start = rows + this.perPage > this.form.carpark.items.length ?
                this.form.carpark.items.length - this.perPage: rows;

                this.$nextTick(() => {
                e.target.scrollTop = scrollTop;
                });
            }, 20);
        },

        selectAll(){
            if (this.select_all){
                this.form.carpark.selected = [];
            }
        },

        removeSelectAll(){
            this.select_all = false;
        },

        onScroll_operator(e) {
            // debounce if scrolling fast
            this.timeout && clearTimeout(this.timeout);
        
            this.timeout = setTimeout(() => {
                const { scrollTop } = e.target;
                const rows = Math.ceil(scrollTop / this.rowHeight);

                this.start_operator = rows + this.perPage_operator > this.form.operator.items.length ?
                this.form.operator.items.length - this.perPage_operator: rows;

                this.$nextTick(() => {
                e.target.scrollTop = scrollTop;
                });
            }, 20);
        },

        selectAll_operator(){
            if (this.select_all_operator){
                this.form.operator.selected = [];
                this.execFilter('operator');
            }
        },

        removeSelectAll_operator(){
            this.select_all_operator = false;
        },
    }
}
</script>

<style lang="scss" scoped>
/* Style the button that is used to open and close the collapsible content */
.collapsible {
  color: black;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
</style>